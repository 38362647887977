import { getApiUrl, authenticatedFetch } from "~/utils/ApiUtils";
import { useQuery } from "@tanstack/react-query";
import {
  AgentType,
  ScenarioV2Type,
  CallScriptV2Type,
  KickoutSensitivityType,
  EscalationListType,
  CareLocationType,
} from "~/types/DomainTypes";
import type { CallConfigurationsType } from "~/types/CallTypes";
import {
  AgentStatusEnum,
  EscalationLevelEnum,
  ScenarioStatusEnum,
  ScriptStatusEnum,
} from "~/constants/DomainConstants";
import { ListFilterType } from "~/types/ApiTypes";

// ============== GET AGENTS ==============

interface GetAgentsParamsType {
  includeKickoutSensitivity?: boolean;
  filterStatus?: AgentStatusEnum[];
  filterDemo?: boolean;
}

export const getAgents = async ({
  includeKickoutSensitivity,
  filterStatus,
  filterDemo,
}: GetAgentsParamsType) => {
  const url = `${getApiUrl()}/agents`;
  const searchParams = new URLSearchParams();
  if (includeKickoutSensitivity) {
    searchParams.append("include_kickout_sensitivity", "true");
  }
  if (filterStatus) {
    const filterStatusStr = filterStatus.join(",");
    searchParams.append("status", filterStatusStr);
  }
  if (filterDemo) {
    searchParams.append("demo", "true");
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const agents = (await response.json()) as AgentType[];
  if (!agents) {
    throw new Error("Invalid response from getAgents.");
  }
  return agents;
};

export const useGetAgentListQuery = (getAgentsParams?: GetAgentsParamsType) => {
  return useQuery({
    queryKey: ["agents", JSON.stringify(getAgentsParams ?? {})],
    queryFn: () => {
      return getAgents(getAgentsParams ?? {});
    },
  });
};

// ============== GET AGENT BY ID ==============

export const getAgentById = async (agentId: string) => {
  const url = `${getApiUrl()}/agent/${agentId}`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const agent = (await response.json()) as AgentType;
  if (!agent) {
    throw new Error("Invalid response from getAgentById.");
  }
  return agent;
};

export const useGetAgentQuery = (agentId?: string | null) => {
  return useQuery({
    queryKey: ["agent", agentId],
    queryFn: ({ queryKey }) => {
      return getAgentById(queryKey[1]!);
    },
    enabled: !!agentId,
  });
};

// ============== GET SCENARIOS ==============

interface GetScenarioParamsType {
  filterStatus?: ScenarioStatusEnum[];
}

export const getScenarios = async ({ filterStatus }: GetScenarioParamsType) => {
  const url = `${getApiUrl()}/scenarios_v2`;
  const searchParams = new URLSearchParams();
  if (filterStatus) {
    const filterStatusStr = filterStatus.join(",");
    searchParams.append("filter_status", filterStatusStr);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const scenarios = (await response.json()) as ScenarioV2Type[];
  if (!scenarios) {
    throw new Error("Invalid response from getScenarios.");
  }
  return scenarios;
};

export const useGetScenarioListQuery = (
  getScenariosParams?: GetScenarioParamsType
) => {
  return useQuery({
    queryKey: ["scenarios", JSON.stringify(getScenariosParams ?? {})],
    queryFn: () => {
      return getScenarios(getScenariosParams ?? {});
    },
  });
};

// ============== GET SCENARIO BY ID ==============

export const getScenarioById = async (scenarioId: string) => {
  const url = `${getApiUrl()}/scenario/${scenarioId}`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const scenario = (await response.json()) as ScenarioV2Type;
  if (!scenario) {
    throw new Error("Invalid response from getScenarioById.");
  }
  return scenario;
};

export const useGetScenarioQuery = (scenarioId?: string | null) => {
  return useQuery({
    queryKey: ["scenario", scenarioId],
    queryFn: ({ queryKey }) => {
      return getScenarioById(queryKey[1]!);
    },
    enabled: !!scenarioId,
  });
};

// ============== GET KICKOUTS ==============

export const getKickoutSensitivities = async () => {
  const response = await authenticatedFetch(
    `${getApiUrl()}/kickout_sensitivities`
  );
  const kickouts = (await response.json()) as KickoutSensitivityType[];
  if (!kickouts) {
    throw new Error("Invalid response from getKickoutSensitivities.");
  }
  return kickouts;
};

export const useGetKickoutListQuery = () => {
  return useQuery({
    queryKey: ["kickouts"],
    queryFn: getKickoutSensitivities,
  });
};

// ============== GET KICKOUT BY ID ==============

export const getKickoutSensitivityById = async (
  kickoutSensitivityId: string
) => {
  const kickouts = await getKickoutSensitivities();
  const result = (kickouts || []).findLast((kickout) => {
    return kickout.kickout_sensitivity_id === kickoutSensitivityId;
  });
  if (!result) {
    throw new Error("No kickout found.");
  }
  return result;
};

export const useGetKickoutQuery = (kickoutId?: string) => {
  return useQuery({
    queryKey: ["kickout", kickoutId],
    queryFn: ({ queryKey }) => {
      return getKickoutSensitivityById(queryKey[1]!);
    },
    enabled: !!kickoutId,
  });
};

// ============== GET SCRIPTS ==============

interface GetScriptsFilterOptionsType {
  scriptStatuses?: ScriptStatusEnum[];
}

export const getScripts = async (
  filterOptions?: GetScriptsFilterOptionsType
) => {
  const url = `${getApiUrl()}/scripts_v2`;
  const searchParams = new URLSearchParams();
  if (filterOptions?.scriptStatuses) {
    searchParams.append("status", filterOptions.scriptStatuses.join(","));
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const scripts = (await response.json()) as CallScriptV2Type[];
  if (!scripts) {
    throw new Error("Invalid response from getScripts.");
  }
  return scripts;
};

export const useGetScriptListQuery = (
  filterOptions?: GetScriptsFilterOptionsType
) => {
  return useQuery({
    queryKey: ["scripts", JSON.stringify(filterOptions)],
    queryFn: () => {
      return getScripts(filterOptions);
    },
  });
};

// ============== GET SCRIPT BY ID ==============

export const getScriptById = async (
  scriptId: string,
  withContent?: boolean
) => {
  const url = `${getApiUrl()}/script/${scriptId}`;
  const searchParams = new URLSearchParams();
  if (withContent) {
    searchParams.append("with_content", "true");
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const script = (await response.json()) as CallScriptV2Type;
  if (!script) {
    throw new Error("Invalid response from getScriptById.");
  }
  return script;
};

export const useGetScriptQuery = (
  scriptId?: string | null,
  withContent?: boolean
) => {
  return useQuery({
    queryKey: ["script", scriptId, withContent],
    queryFn: () => {
      if (!scriptId) {
        return;
      }
      return getScriptById(scriptId, withContent);
    },
    enabled: !!scriptId,
  });
};

// ============== GET CALL CONFIGURATIONS ==============

export const getCallConfigurations = async () => {
  const url = `${getApiUrl()}/calls/configurations`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const payload = (await response.json()) as CallConfigurationsType;
  if (!payload) {
    throw new Error("Invalid response from getCallConfigurations.");
  }
  return payload;
};

// ============== GET ESCALATIONS  ===============

export interface EscalationListFilterType extends ListFilterType {
  scriptId?: string;
  patientId?: string;
  escalationLevels?: EscalationLevelEnum[];
}

export const getEscalationList = async (
  filterObj?: EscalationListFilterType
) => {
  const url = `${getApiUrl()}/calls/escalations`;
  const searchParams = new URLSearchParams();
  if (filterObj?.limit) {
    searchParams.append("limit", filterObj?.limit.toString());
  }
  if (filterObj?.offset) {
    searchParams.append("offset", filterObj?.offset.toString());
  }
  if (filterObj?.escalationLevels) {
    searchParams.append(
      "escalation_levels",
      filterObj?.escalationLevels.join(",")
    );
  }
  if (filterObj?.scriptId) {
    searchParams.append("script_id", filterObj?.scriptId);
  }
  if (filterObj?.patientId) {
    searchParams.append("patient_id", filterObj?.patientId);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const payload = (await response.json()) as {
    escalations: EscalationListType[];
    total_count: number;
  };
  if (!payload) {
    throw new Error("Invalid response from getEscalations.");
  }
  return payload;
};

export const useGetEscalationListQuery = (
  filterObj?: EscalationListFilterType
) => {
  return useQuery({
    queryKey: ["escalations", JSON.stringify(filterObj ?? {})],
    queryFn: () => {
      return getEscalationList(filterObj ?? {});
    },
  });
};

// ============== GET COMPILED SCRIPTS ==============

export const getCompiledScript = async (
  scriptId?: string,
  patientId?: string,
  agentId?: string
) => {
  const url = `${getApiUrl()}/script/${scriptId}/compiled`;
  const searchParams = new URLSearchParams();
  if (patientId) {
    searchParams.append("patient_id", patientId);
  }
  if (agentId) {
    searchParams.append("agent_id", agentId);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const { context } = (await response.json()) as { context: string };
  if (!context) {
    throw new Error("Invalid response from getCompiledScript.");
  }
  return context;
};

export const useGetCompiledScriptQuery = (
  scriptId?: string,
  patientId?: string,
  agentId?: string
) => {
  return useQuery({
    queryKey: ["compiledScript", scriptId, patientId, agentId],
    queryFn: () => {
      return getCompiledScript(scriptId, patientId, agentId);
    },
    enabled: !!scriptId && !!patientId && !!agentId,
  });
};

// ============== GET CARE LOCATIONS ==============

export const getCareLocations = async () => {
  const url = `${getApiUrl()}/care-locations`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const careLocations = (await response.json()) as CareLocationType[];
  if (!careLocations) {
    throw new Error("Invalid response from getCareLocations.");
  }
  return careLocations;
};

export const useGetCareLocationsQuery = () => {
  return useQuery({
    queryKey: ["careLocations"],
    queryFn: () => {
      return getCareLocations();
    },
  });
};
