export enum DateRangeEnum {
  TODAY = "today",
  THIS_WEEK = "this_week",
  LAST_WEEK = "last_week",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  MONTH_TO_DATE = "month_to_date",
  CUSTOM = "custom",
}

export const DateRangeOptionsMap = {
  [DateRangeEnum.TODAY]: {
    label: "Today",
    value: DateRangeEnum.TODAY,
  },
  [DateRangeEnum.THIS_WEEK]: {
    label: "This Week",
    value: DateRangeEnum.THIS_WEEK,
  },
  [DateRangeEnum.LAST_WEEK]: {
    label: "Last Week",
    value: DateRangeEnum.LAST_WEEK,
  },
  [DateRangeEnum.THIS_MONTH]: {
    label: "This Month",
    value: DateRangeEnum.THIS_MONTH,
  },
  [DateRangeEnum.LAST_MONTH]: {
    label: "Last Month",
    value: DateRangeEnum.LAST_MONTH,
  },
  [DateRangeEnum.MONTH_TO_DATE]: {
    label: "Month to Date",
    value: DateRangeEnum.MONTH_TO_DATE,
  },
};

export const DateRangeOptions = Object.values([
  DateRangeOptionsMap[DateRangeEnum.TODAY],
  DateRangeOptionsMap[DateRangeEnum.THIS_WEEK],
  DateRangeOptionsMap[DateRangeEnum.LAST_WEEK],
  DateRangeOptionsMap[DateRangeEnum.MONTH_TO_DATE],
]);
