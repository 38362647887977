import lodashToLower from "lodash/toLower";
import lodashToUpper from "lodash/toUpper";
import lodashCapitalize from "lodash/capitalize";
import libPluraize from "pluralize";

// ===== String Utils =====

/**
 * Formats a enum string value into something nicer for display
 * @param value an enum value coming from the backend
 * @returns a string formatted for display
 */
export const formatEnumValue = (value?: string | null) => {
  if (!value) {
    return;
  }
  // Split the value on underscores, capitalize the first letter of each word
  return value
    .split("_")
    .map((word) => {
      return formatCapitalize(word);
    })
    .join(" ");
};

export const formatCapitalize = (
  value?: string | null,
  separator?: string | null
) => {
  if (!value) {
    return;
  }
  separator = separator || " ";
  // Capitalize all words except the commonly not capitalized ones
  return (value + "")
    .split(separator)
    .map((word, index) => {
      // if the all the characters are uppercase, return the word as is
      if (word.toUpperCase() === word) {
        return word;
      }
      if (
        index > 0 &&
        ["a", "an", "the", "and", "but", "or", "for", "nor", "at"].includes(
          word
        )
      ) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(separator);
};

export const formatListAsString = (value: string[] | null) => {
  if (!value) {
    return "";
  }
  return value.join(", ");
};

export const formatTitleCase = (value: string | null) => {
  if (!value) return "";
  const result = (value + "").replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const truncateToCharacters = (value: string, length: number) => {
  if (!value) {
    return "";
  }
  return value.length > length
    ? `${value.substring(0, length).trim()}...`
    : value;
};

export const shortenUUID = (uuid: string) => {
  return uuid.substring(0, 8);
};

export const shortenString = (value: string, length: number = 24) => {
  return `${value.substring(0, length)}...`;
};

export const convertNullStrings = (value?: string | null) => {
  // if current_employers is "None", "N/A", "null", or "undefined", set to null
  const lower = value ? lodashToLower(value) : value;
  if (
    lower === "none" ||
    lower === "n/a" ||
    lower === "na" ||
    lower === "null" ||
    lower === "undefined" ||
    lower === "not applicable" ||
    lower === "no"
  ) {
    value = null;
  }
  return value;
};

export const sanitizeNewLineString = (value: string) => {
  return value.replaceAll("\\n", "\n");
};

export const toLower = lodashToLower;
export const toUpper = lodashToUpper;
export const capitalize = lodashCapitalize;
export const pluralize = libPluraize;
