import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useIsSuperAdmin from "~/hooks/useIsSuperAdmin";
import { useGetVersionQuery } from "~/api/GeneralApi";

const EnvironmentHeader = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const {
    data: versionData,
    isPending: versionPending,
    isError: versionError,
  } = useGetVersionQuery(true);
  if (!isSuperAdmin || versionPending || versionError) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          bgcolor: "grey.300",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
      >
        <Typography
          variant={"body2"}
          color="text.primary"
          sx={{
            fontSize: "0.575rem",
            px: 4,
            my: "2px",
          }}
        >{`Server: ${versionData.version}`}</Typography>
      </Box>
    </>
  );
};

export default EnvironmentHeader;
